<template>
    <div class="input-group">
        <v-select :clearable="false" :searchable="false" class="w-100" :options="fieldData.options" v-model="groupTimeValue"> </v-select>
    </div>
</template>

<script>
import props from "@/mixins/inputProps"
import vSelect from "vue-select"
import dayjs from "dayjs"
import * as Mutations from "@/store/modules/channels/mutation-types"
import globalConfig from "../../../../mixins/globalConfig"

export default {
    name: "DropdownGroupTime",
    mixins: [props, globalConfig],
    components: {
        vSelect,
    },
    data() {
        return {
            groupTimeValue: { label: "Dag", id: "day" },
        }
    },
    async mounted() {
        await this.loadGlobalConfigFromFile()
        if (!this.config_EnableWeekAggChannel) {
            this.fieldData.options = this.fieldData.options.filter((obj) => obj !== undefined && obj.id !== "week")
        }
        this.$store.commit(Mutations.setChannelsQuery, {
            ...this.$store.getters.getChannelsQuery,
            grouptime: "day",
        })

        this.$emit("onEnableConsumtionDateTo")
    },
    watch: {
        groupTimeValue(groupTime) {
            this.$store.commit(Mutations.setChannelsQuery, {
                ...this.$store.getters.getChannelsQuery,
                grouptime: groupTime.id,
            })
            let publishToDate = dayjs(this.$store.getters.getMetadataProgramToDate)
            if (groupTime.id === "sevendays") {
                this.$emit("onDisableConsumtionDateTo")
                let startDate = dayjs(this.$store.getters.getChannelsQuery.start_date)
                let newEndDate = startDate.add(6, "d")
                if (newEndDate.isAfter(publishToDate)) {
                    newEndDate = publishToDate
                    let newStartDate = newEndDate.subtract(6, "d")
                    this.$store.commit(Mutations.setChannelsStartdate, newStartDate.format("YYYY-MM-DD"))
                }
                this.$store.commit(Mutations.setChannelsEnddate, newEndDate.format("YYYY-MM-DD"))
            } else if (groupTime.id === "week") {
                var weekday = require("dayjs/plugin/weekday")
                dayjs.extend(weekday)
                //if publishtodate is not sunday set endate to previous sunday.
                if (publishToDate.get("d") !== 0) {
                    let newEndDate = publishToDate.weekday(-1)
                    this.$store.commit(Mutations.setChannelsEnddate, newEndDate.format("YYYY-MM-DD"))
                }
                //else set channelsenddate to publishtodate
                else this.$store.commit(Mutations.setChannelsEnddate, publishToDate.format("YYYY-MM-DD"))

                let newChannelStartDate = ""
                //if publishtodate is sunday set startdate to this weeks monday
                if (dayjs(publishToDate).get("d") === 0) newChannelStartDate = dayjs(publishToDate).weekday(0).format("YYYY-MM-DD")
                //else set to previous monday
                else {
                    newChannelStartDate = dayjs(publishToDate).weekday(-7).format("YYYY-MM-DD")
                }
                this.$store.commit(Mutations.setChannelsStartdate, newChannelStartDate)

                this.$emit("onEnableConsumtionDateTo")
            } else this.$emit("onEnableConsumtionDateTo")
        },
    },
}
</script>

<style scoped>
.input-group {
    font-size: 0.875rem;
}
>>> .vs__selected-options {
    height: 1.6rem;
}
</style>
