<template>
    <div>
        <ConfirmDialog :dialogId="confirmDialogId" @cancel="confirmDialog" @ok="confirmDialog" />
        <b-button size="sm" variant="outline-secondary" :class="['w-100 btn-overflow', getTargetGroupCount() > 0 ? 'selectedItemsClass' : '']" v-b-modal.modal-target @keydown.enter.prevent>
            {{ getButtonText }}
        </b-button>
        <b-modal @ok="saveTargetToLocalStorage" @show="showModal" @hide="onHidden" :no-close-on-backdrop="true" id="modal-target" dialog-class="targetgroupmodal" title="Målgrupper / Mått">
            <b-toast id="targetGroupExist" title="Varning" variant="warning" :solid="true" auto-hide-delay="3000">
                <span v-html="targetToastWarningMsg"> </span>
            </b-toast>
            <div class="form-row">
                <div v-for="(select, index) in selects" :key="index" :class="select.class">
                    <label>
                        {{ select.label }}
                        <div v-on:click="select.identifier == 'age_from' || select.identifier == 'age_to' ? clickedAgeFromOrToSelect(select.identifier) : ''">
                            <b-form-select :disabled="select.disabled" size="sm" :options="select.options" v-model="select.value" @change="clickedSelect(select.identifier)"></b-form-select>
                        </div>
                    </label>
                </div>
                <b-form-group v-if="config_ShowModelSelection">
                    <b-form-checkbox-group v-model="selectedSources" :options="sources" class="px-1"></b-form-checkbox-group>
                </b-form-group>
                <div class="col-sm-12 mt-3 mb-2">
                    <b-button class="mr-2" variant="success" size="sm" @click="saveTarget"> Lägg till </b-button>
                    <b-button v-if="targetGroups.length > 1" variant="danger" size="sm" @click="removeTargets"> Ta bort alla </b-button>
                </div>

                <b-list-group class="mt-3 w-100">
                    <b-list-group-item v-for="(targetGroup, index) in targetGroups" :key="index">
                        <span class="targetgroup" v-html="stringifyTarget(targetGroup, true)"> </span>
                        <b-button class="mr-2 float-right" variant="danger" size="sm" @click="removeTarget(index)"> Ta bort </b-button>
                    </b-list-group-item>
                </b-list-group>
            </div>
            <template #modal-footer="{ cancel, ok }">
                <b-button size="sm" variant="outline-secondary" @click="cancel()"> Avbryt </b-button>
                <b-button size="sm" variant="primary" @click="ok()"> Spara </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import ConfirmDialog from "@/components/Shared/FormElement/Dialogs/ConfirmDialog"
import formSelects from "./formSelects"
import props from "@/mixins/inputProps"
import globalConfig from "../../../../mixins/globalConfig"
export default {
    name: "TargetGroupChannels",
    components: {
        ConfirmDialog,
    },
    mixins: [props, globalConfig],
    props: {
        fieldData: Object,
    },
    data() {
        return {
            selects: formSelects(this.$props.fieldData.config),
            selectId: "",
            currentTargetGroups: null,
            closeWithoutSaving: false,
            confirmDialogId: "target-modal-confirm",
            selectedSources: ["Total"],
            sources: [
                { text: "TV", value: "Tv" },
                { text: "Online", value: "Online" },
                { text: "Total", value: "Total" },
            ],
            targetToastWarningMsg: "",
        }
    },
    async mounted() {
        this.setTargetGroupsFromLocalStorage()
        this.currentTargetGroups = this.$store.getters[this.getter].target_groups
        await this.loadGlobalConfigFromFile()

        if (this.config_EnableReachTimeChannel) {
            this.setAvailableReachOptions()
        } else {
            this.setAvailableReachOptions(["1"], ["15"])
        }
        this.setAvailableSelections("reach")
    },
    computed: {
        targetGroups() {
            return this.$store.getters[this.getter]?.[this.fieldData.identifier] ? this.$store.getters[this.getter][this.fieldData.identifier] : []
        },
        getButtonText() {
            let NumberOf = this.getTargetGroupCount()

            if (NumberOf === 0) return "Målgrupp / Mått"
            if (NumberOf !== 1) {
                return "Målgrupp / Mått (" + NumberOf + " valda)"
            }
            return "Målgrupp / Mått (" + NumberOf + " vald)"
        },
    },
    methods: {
        onHidden(e) {
            if (e.trigger == "ok" || e.trigger == "event") {
                return
            }
            let madeChanges = false
            let selectedTargets = this.$store.getters[this.getter][this.fieldData.identifier]
            let storedTargets = localStorage.getItem("targetGroups") ? JSON.parse(localStorage.getItem("targetGroups")) : []
            storedTargets = storedTargets[this.getter] ? storedTargets[this.getter] : []
            if (selectedTargets.length != storedTargets.length) madeChanges = true
            else {
                let selectedTargetsStrings = selectedTargets.map(function(target) {
                    let stringifiedTarget = Object.values(target.selectedMeasureText).join(" ")
                    return stringifiedTarget
                })
                for (let i = 0; i < storedTargets.length; i++) {
                    let storedTarget = Object.values(storedTargets[i].selectedMeasureText).join(" ")
                    if (!selectedTargetsStrings.includes(storedTarget)) madeChanges = true
                }
            }
            if (madeChanges) {
                this.$bvModal.show(this.confirmDialogId)
                e.preventDefault()
            }
        },
        confirmDialog(e) {
            if (e.trigger == "ok") this.$bvModal.hide("modal-target")
        },
        getTargetGroupCount() {
            this.$store.getters[this.getter][this.fieldData.identifier]

            let storedTargets = localStorage.getItem("targetGroups") ? JSON.parse(localStorage.getItem("targetGroups")) : []
            if (storedTargets) {
                if (typeof storedTargets[this.getter] === "undefined") storedTargets = null
            }

            return storedTargets != null ? storedTargets[this.getter].length : 0
        },
        showModal() {
            this.setTargetGroupsFromLocalStorage()
        },
        setTargetGroupsFromLocalStorage() {
            let storedTargets = JSON.parse(localStorage.getItem("targetGroups"))
            let updatedStoredTargets = !storedTargets || !storedTargets[this.getter] ? [] : storedTargets[this.getter]
            const updatedQuery = {
                ...this.$store.getters[this.getter],
                [this.fieldData.identifier]: updatedStoredTargets,
            }
            this.$store.commit(this.setter, updatedQuery)
        },
        clickedAgeFromOrToSelect(selectId) {
            this.selectId = selectId
        },

        saveTargetToLocalStorage() {
            let selectedTargets = this.$store.getters[this.getter][this.fieldData.identifier]

            const updatedQuery = {
                ...this.$store.getters[this.getter],
                [this.fieldData.identifier]: selectedTargets,
            }

            this.$store.commit(this.setter, updatedQuery)
            const storedTargets = localStorage.getItem("targetGroups") ? JSON.parse(localStorage.getItem("targetGroups")) : {}
            storedTargets[this.getter] = [...this.targetGroups]
            localStorage.setItem("targetGroups", JSON.stringify(storedTargets))
        },
        sortSelectedSources(sources) {
            this.selectedSources.sort((a, b) => sources.indexOf(a) - sources.indexOf(b))
        },
        saveTarget() {
            let maxTargetGroupCount
            if (Number.isInteger(this.config_NumberOfTargetGroupsChannel)) maxTargetGroupCount = this.config_NumberOfTargetGroupsChannel
            else maxTargetGroupCount = 6
            if (this.targetGroups.length + this.selectedSources.length > maxTargetGroupCount) {
                this.$bvToast.toast(`Max antal målgrupper är ${maxTargetGroupCount}`, {
                    title: "Varning",
                    variant: "warning",
                    solid: true,
                    autoHideDelay: 3000,
                })
                return
            }
            let query = {}
            this.selects.map(select => {
                query[select.identifier] = select.value
            })
            this.sortSelectedSources(this.sources.map(s => s.value))
            let updatedTargets = [...this.targetGroups]
            for (let i = 0; i < this.selectedSources.length; i++) {
                query.source = this.selectedSources[i]
                query.selectedMeasureText = this.stringifyTarget(query)
                //check if measure is already added
                let targetGroupStrings = updatedTargets.map(t => t.selectedMeasureText.header + t.selectedMeasureText.subHeader)
                if (targetGroupStrings.includes(query.selectedMeasureText.header + query.selectedMeasureText.subHeader)) {
                    this.$bvToast.toast(`Målgruppen ${query.selectedMeasureText.header + " " + query.selectedMeasureText.subHeader} finns redan`, {
                        title: "Varning",
                        variant: "warning",
                        solid: true,
                    })
                    continue
                }
                updatedTargets.push({ ...query })
            }
            const updatedQuery = {
                ...this.$store.getters[this.getter],
                [this.fieldData.identifier]: updatedTargets,
            }
            this.$store.commit(this.setter, updatedQuery)
        },
        removeTarget(index) {
            const updatedTargets = [...this.targetGroups]
            updatedTargets.splice(index, 1)

            const updatedQuery = {
                ...this.$store.getters[this.getter],
                [this.fieldData.identifier]: updatedTargets,
            }
            this.$store.commit(this.setter, updatedQuery)
        },
        removeTargets() {
            const updatedQuery = {
                ...this.$store.getters[this.getter],
                [this.fieldData.identifier]: [],
            }
            return this.$store.commit(this.setter, updatedQuery)
        },
        stringifyTarget(targetGroup, isVHtml = false) {
            let measureText = {}
            measureText.header = this.fieldData.config.measures.find(x => x.value == targetGroup.measure).selectedText
            let reachTvAndOnlineText = ""
            if (targetGroup.measure !== "rating" && targetGroup.measure !== "avgtimeviewed") {
                let selectedMeasureReachTv = this.selects.find(s => s.identifier == "reach_tv").options.find(o => o.value == targetGroup.reach_tv).selectedReachTvText
                let selectedMeasureReachOnline = this.selects.find(s => s.identifier == "reach_online").options.find(o => o.value == targetGroup.reach_online).selectedReachOnlineText
                reachTvAndOnlineText = `(${selectedMeasureReachTv} & ${selectedMeasureReachOnline}) `
            }
            let ageGroup = this.selects.find(s => s.identifier == "group").options.find(o => o.value == targetGroup.group).selectedAgeGroupText
            let sourceText = this.sources.find(s => s.value == targetGroup.source).text
            measureText.subHeader = `${ageGroup}${targetGroup.age_from}-${targetGroup.age_to}  ${reachTvAndOnlineText}${sourceText}`
            if (isVHtml) {
                return `${measureText.header} <span class="measureagewrapper">${measureText.subHeader}</span>`
            }
            return measureText
        },
        clickedSelect(identifier) {
            if (identifier == "age_from" || identifier == "age_to") {
                let ageToSelect = this.selects.find(select => select.identifier == "age_to")
                let ageFromSelect = this.selects.find(select => select.identifier == "age_from")
                if (parseInt(ageFromSelect.value) > parseInt(ageToSelect.value)) {
                    if (this.selectId == "age_from") {
                        let newToSelect = ""
                        ageToSelect.options.forEach(e => {
                            if (newToSelect == "" && parseInt(e.value) > parseInt(ageFromSelect.value)) {
                                newToSelect = e.value
                            }
                        })
                        ageToSelect.value = newToSelect
                    } else {
                        let newFromSelect = ""
                        ageFromSelect.options.forEach(el => {
                            if (parseInt(ageToSelect.value) > parseInt(el.value)) {
                                newFromSelect = el.value
                            }
                        })
                        ageFromSelect.value = newFromSelect
                    }
                }
            } else if (identifier == "measure") {
                let chosenMeasure = this.selects.find(select => select.identifier == identifier).value
                this.setAvailableSelections(chosenMeasure)
            }
        },
        setAvailableSelections(chosenMeasure) {
            if (chosenMeasure === "reach" || chosenMeasure === "reach_percent") {
                if (this.config_EnableAgeLimitedChannel4Reach) {
                    this.setAvailableAgeGroups(["3", "15", "30", "45", "60"], ["14", "29", "44", "59", "99"])
                } else {
                    if (this.config_EnableAgeChannel4Reach) {
                        this.setAvailableAgeGroups()
                    } else {
                        this.setAvailableAgeGroups(["3"], ["99"])
                    }
                }
            } else if (chosenMeasure === "avgtimeviewed") {
                if (this.config_EnableAgeChannel4Time) {
                    this.setAvailableAgeGroups()
                } else {
                    this.setAvailableAgeGroups(["3"], ["99"])
                }
            } else if (chosenMeasure === "rating" || chosenMeasure === "rating_percent") {
                if (this.config_EnableAgeChannel4Rating) {
                    this.setAvailableAgeGroups()
                } else {
                    this.setAvailableAgeGroups(["3"], ["99"])
                }
            } else {
                this.setAvailableAgeGroups()
            }
        },
        setAvailableAgeGroups(agesFrom, agesTo) {
            const all_age_from = formSelects(this.$props.fieldData.config).find(obj => {
                return obj.identifier === "age_from"
            })
            const ageFromIndex = this.selects.findIndex(obj => {
                return obj.identifier == "age_from"
            })
            const all_age_to = formSelects(this.$props.fieldData.config).find(obj => {
                return obj.identifier === "age_to"
            })
            const ageToIndex = this.selects.findIndex(obj => {
                return obj.identifier == "age_to"
            })
            //update available age options
            if (agesFrom?.length) {
                this.selects[ageFromIndex].options = all_age_from.options.filter(age => {
                    return agesFrom.includes(age.text)
                })
            } else {
                this.selects[ageFromIndex].options = all_age_from.options
            }
            if (agesTo?.length) {
                this.selects[ageToIndex].options = all_age_to.options.filter(age => {
                    return agesTo.includes(age.text)
                })
            } else {
                this.selects[ageToIndex].options = all_age_to.options
            }

            //set disabled state
            this.selects[ageFromIndex].disabled = this.selects[ageFromIndex].options.length == 1 ? true : false
            this.selects[ageToIndex].disabled = this.selects[ageToIndex].options.length == 1 ? true : false

            //update values in UI
            this.selects[ageFromIndex].value = this.selects[ageFromIndex].options[0].value
            this.selects[ageToIndex].value = this.selects[ageToIndex].options[this.selects[ageToIndex].options.length - 1].value
        },
        setAvailableReachOptions(reachesTV, reachesOnline) {
            const all_reach_tv = formSelects(this.$props.fieldData.config).find(obj => {
                return obj.identifier === "reach_tv"
            })
            const reachTvIndex = this.selects.findIndex(obj => {
                return obj.identifier == "reach_tv"
            })
            const all_reach_online = formSelects(this.$props.fieldData.config).find(obj => {
                return obj.identifier === "reach_online"
            })
            const reachOnlineIndex = this.selects.findIndex(obj => {
                return obj.identifier == "reach_online"
            })
            //update available reach options
            if (reachesTV?.length) {
                this.selects[reachTvIndex].options = all_reach_tv.options.filter(reach => {
                    return reachesTV.includes(reach.value)
                })
            } else {
                this.selects[reachTvIndex].options = all_reach_tv.options
            }
            if (reachesOnline?.length) {
                this.selects[reachOnlineIndex].options = all_reach_online.options.filter(reach => {
                    return reachesOnline.includes(reach.value)
                })
            } else {
                this.selects[reachOnlineIndex].options = all_reach_online.options
            }

            //set disabled state
            this.selects[reachTvIndex].disabled = this.selects[reachTvIndex].options.length == 1 ? true : false
            this.selects[reachOnlineIndex].disabled = this.selects[reachOnlineIndex].options.length == 1 ? true : false

            //update values in UI
            this.selects[reachTvIndex].value = "1"
            this.selects[reachOnlineIndex].value = "15"
        },
    },
}
</script>

<style>
.targetgroupmodal {
    min-width: 33rem;
}
.custom-control-label {
    display: inline-flex;
    align-items: center;
}
</style>
<style scoped>
.targetgroup .measureagewrapper {
    font-size: 0.8rem;
}
label {
    width: 100%;
}
.form-selects label div {
    margin-top: 5.6px;
}

.selectedItemsClass {
    background-color: #e5f3ff;
    border-color: #337ab7;
    color: #6c757d;
}
</style>
