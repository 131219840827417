export const mixin = {
    methods: {
        inputChanged(errorState, identifier) {
            let newFormFields = {}
            let formFieldsKeys = Object.keys(this.formFields)
            let formFieldsValues = Object.values(this.formFields)
            for (let i = 0; i < formFieldsValues.length; i++) {
                let formField = { ...formFieldsValues[i] }
                if (formFieldsValues[i].identifier === identifier) {
                    formField.hasError = errorState ? true : false
                }
                newFormFields = { ...newFormFields, [formFieldsKeys[i]]: formField }
            }
            this.formFields = newFormFields
        },
        validateForm() {
            let hasErrors = false
            Object.values(this.formFields).reduce((result, field) => {
                if (field.hasError) hasErrors = true
            }, {})
            if (this.formErrors.length > 0) hasErrors = true
            return hasErrors
        },
    },
}
export default mixin
