<template>
    <div>
        <div v-if="showPublishDatePickers">
            <b-row>
                <b-col lg="6">
                    <label class="label-text">
                        Publiceringsdatum från
                    </label>
                    <date-pick v-model="pubDateFrom" :months="datePickerConfig.months" :weekdays="datePickerConfig.weekdays" :is-date-disabled="isDateDisabled" class="pubDateFrom"> </date-pick>
                </b-col>
                <b-col lg="6" class="pl-0">
                    <label class="label-text">
                        Publiceringsdatum till
                    </label>
                    <div class="wrapsmallbtns">
                        <b-button size="sm" @click="hidePubDate" title="använd ej" variant="primary" class="pubdatetobtn">använd ej</b-button>
                    </div>
                    <date-pick v-model="pubDateTo" :months="datePickerConfig.months" :weekdays="datePickerConfig.weekdays" :is-date-disabled="isDateDisabled"> </date-pick>
                </b-col>
            </b-row>
        </div>
        <b-button v-else @click="clickedPublishDate" size="sm" variant="outline-secondary" :class="'w-100 btn-overflow'">
            Använd pubdatum
        </b-button>
    </div>
</template>

<script>
import DatePick from "vue-date-pick"
import props from "@/mixins/inputProps"
import propsDate from "@/mixins/inputPropsDate"
import * as Mutations from "@/store/modules/programs/mutation-types"
import { isDateNotBetweenTwoDates } from "../../../../api/utils.js"
import globalConfig from "@/mixins/globalConfig"
export default {
    name: "PublishDate",
    components: {
        DatePick,
    },
    mixins: [props, propsDate, globalConfig],
    async mounted() {
        await this.loadGlobalConfigFromFile()
    },
    computed: {
        showPublishDatePickers() {
            return this.$store.getters.getProgramsQuery.searchForPubDate
        },
        pubDateFrom: {
            get() {
                if (this.$store.getters[this.getter].pub_start_date > this.$store.getters[this.getter].start_date) {
                    this.$store.commit(Mutations.setProgramsPubStartdate, this.$store.getters[this.getter].start_date)
                }
                return this.$store.getters[this.getter]?.pub_start_date ? this.$store.getters[this.getter].pub_start_date : ""
            },
            set(val) {
                let updatedQuery
                if (this.config_ShowConsumtionPeriodProgram && val > this.$store.getters[this.getter].end_date) {
                    val = this.$store.getters[this.getter].end_date
                } else if (!this.config_ShowConsumtionPeriodProgram && val > this.$store.getters[this.getter].start_date) {
                    val = this.$store.getters[this.getter].start_date
                } else if (val > this.$store.getters[this.getter].pub_end_date) {
                    if (val > this.$store.getters[this.getter].start_date) {
                        val = this.$store.getters[this.getter].start_date
                    }
                    updatedQuery = {
                        ...this.$store.getters[this.getter],
                        pub_end_date: val,
                        pub_start_date: val,
                    }
                    return this.$store.commit(this.setter, updatedQuery)
                }
                this.$store.commit(Mutations.setProgramsPubStartdate, val)
            },
        },
        pubDateTo: {
            get() {
                if (!this.config_ShowConsumtionPeriodProgram && this.$store.getters[this.getter].pub_end_date > this.$store.getters[this.getter].start_date) {
                    this.$store.commit(Mutations.setProgramsPubEnddate, this.$store.getters[this.getter].start_date)
                } else if (this.$store.getters[this.getter].pub_end_date > this.$store.getters[this.getter].end_date) {
                    this.$store.commit(Mutations.setProgramsPubEnddate, this.$store.getters[this.getter].end_date)
                }
                return this.$store.getters[this.getter]?.pub_end_date ? this.$store.getters[this.getter].pub_end_date : ""
            },
            set(val) {
                let updatedQuery
                if (val > this.$store.getters[this.getter].end_date) {
                    val = this.$store.getters[this.getter].end_date
                } else if (val < this.$store.getters[this.getter].pub_start_date) {
                    updatedQuery = {
                        ...this.$store.getters[this.getter],
                        pub_end_date: val,
                        pub_start_date: val,
                    }
                    return this.$store.commit(this.setter, updatedQuery)
                }
                this.$store.commit(Mutations.setProgramsPubEnddate, val)
            },
        },
    },
    data() {
        return {
            datePickerConfig: {
                weekdays: ["Må", "Ti", "On", "To", "Fr", "Lö", "Sö"],
                months: ["Januari", "Februari", "Mars", "April", "Maj", "Juni", "Juli", "Augusti", "September", "Oktober", "November", "December"],
            },
        }
    },
    methods: {
        clickedPublishDate() {
            this.$store.commit(Mutations.setProgramsSearchForPubDate, true)
            this.$store.commit(Mutations.setProgramsPubStartdate, this.$store.getters.getProgramsQuery.start_date)
            this.$store.commit(Mutations.setProgramsPubEnddate, this.$store.getters.getProgramsQuery.end_date)
        },
        hidePubDate() {
            this.$store.commit(Mutations.setProgramsSearchForPubDate, false)
        },
        isDateDisabled(datepickerDate) {
            let startDate = new Date(this.$store.getters.getPublishedFromDate)
            startDate.setDate(startDate.getDate() - 30)
            let endDate = new Date(this.latestDate)
            let disableDate = isDateNotBetweenTwoDates(datepickerDate, startDate, endDate)
            return disableDate
        },
    },
}
</script>
<style scoped>
input {
    height: 30px !important;
    box-sizing: border-box;
    border: 1px solid #ced4da;
    padding: 2px 5px !important;
}

.pubDatePicker {
    width: 50%;
    float: right;
}
.pubdatetobtn {
    font-size: 0.5625em;
    padding-right: 0.188rem;
    padding-left: 0.188rem;
    padding-top: 0.2em;
    margin-left: 0.188rem;
    float: right;
}
</style>
