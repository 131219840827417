const mixin = {
    props: {
        mutationSetStartdate: String,
        mutationSetEnddate: String,
        latestDate: String,
        parentName: String,
    },
}

export default mixin
