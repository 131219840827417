<template>
    <div>
        <div class="stDiv">
            <label
                class="stlabel"
                title="Reklam: filmkoder med prefix 1
Sponsring: filmkoder med prefix 2, 3, 4, 6
Trailer: filmkoder med prefix 5"
            >
                <b-icon class="stInfo" icon="info-circle"></b-icon>
            </label>
        </div>
        <b-dropdown class="w-100 spottypes" variant="outline-secondary" :text="label">
            <b-form-group>
                <b-form-checkbox v-model="allIsSelected" @change="selectAll" class="mx-2">
                    {{ allIsSelected ? "Avmarkera alla" : "Välj Alla" }}
                </b-form-checkbox>
                <b-form-checkbox-group v-model="value" :options="fieldData.config.options" class="mx-2" @click.native="allIsSelected = false" stacked></b-form-checkbox-group>
            </b-form-group>
        </b-dropdown>
    </div>
</template>

<script>
import computedVal from "@/mixins/computedVal"
import props from "@/mixins/inputProps"
export default {
    name: "CheckboxDropdownSpottypes",
    mixins: [computedVal, props],
    data() {
        return {
            allIsSelected: false,
        }
    },
    computed: {
        label: function() {
            let selected = this.$store.getters[this.getter]?.[this.fieldData.identifier] ? this.$store.getters[this.getter][this.fieldData.identifier] : []

            const NumberOf = selected.length

            return NumberOf != 1 ? this.fieldData.label + " (" + NumberOf + " valda)" : this.fieldData.label + " (" + NumberOf + " vald)"
        },
    },
    created() {
        let spotTypes = []
        this.fieldData.config.options.forEach(opt => {
            switch (opt) {
                case "S":
                    spotTypes.push({ text: "Reklam", value: opt })
                    break
                case "T":
                    spotTypes.push({ text: "Trailer", value: opt })
                    break
                case "B":
                    spotTypes.push({ text: "Sponsring", value: opt })
                    break
            }

            this.fieldData.config.options = spotTypes
        })
    },
    methods: {
        selectAll(checked) {
            let updatedQuery

            if (checked) {
                updatedQuery = {
                    ...this.$store.getters[this.getter],
                    [this.fieldData.identifier]: this.fieldData.config.options.map(opt => opt.value),
                }
            } else {
                let options = this.fieldData.config.options.filter(opt => {
                    if (opt.disabled) return opt
                })
                updatedQuery = {
                    ...this.$store.getters[this.getter],
                    [this.fieldData.identifier]: options,
                }
            }

            return this.$store.commit(this.setter, updatedQuery)
        },
    },
}
</script>

<style>
.spottypes button {
    width: 100%;
    font-size: 14px;
    padding: 0.25rem 0.75rem;
}

.stDiv {
    margin-top: -5px;
    float: left;
    margin-left: 5px;
    height: 13px;
}

.stlabel {
    font-weight: normal;
    font-size: xx-small;
}
.stInfo {
    transform: scale(1.4);
    margin-left: 2px;
}
</style>
