<template>
    <div class="text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
        <div>
            <small class="text-muted">{{ loadingMsg }}</small>
        </div>
    </div>
</template>

<script>
export default {
    name: "Spinner",
    props: {
        loadingMsg: {
            type: String,
        },
    },
}
</script>

<style scoped></style>
