<template>
    <b-form-timepicker v-model="value" locale="sv" size="sm"></b-form-timepicker>
</template>

<script>
import computedVal from "@/mixins/computedVal"
import props from "@/mixins/inputProps"
export default {
    name: "TimePicker",
    mixins: [computedVal, props],
}
</script>

<style scoped></style>
