<template>
    <div>
        <ConfirmDialog @ok="confirmDialog" :dialogId="confirmDialogId" />
        <b-button
            size="sm"
            variant="outline-secondary"
            :class="['w-100 btn-overflow', getSelectedChannelCount() > 0 ? 'selectedItemsClass' : '']"
            v-b-modal:[fieldData.identifier]
            @keydown.enter.prevent
        >
            {{ getOpenModalButtonText }}
        </b-button>
        <b-modal :id="fieldData.identifier" :title="fieldData.title" :size="'lg'" @show="onPickerToggle" @cancel="userClosePicker" @close="userClosePicker" :no-close-on-backdrop="true">
            <b-container>
                <b-row class="row align-items-start">
                    <b-col class="col-12 mb-3">
                        <b-button size="sm" @click="selectAllChannels" variant="primary">
                            Välj samtliga
                        </b-button>
                        <b-button size="sm" class="ml-3" variant="danger" @click="clearChannels">
                            Rensa val
                        </b-button>
                    </b-col>
                </b-row>
                <b-row class="row align-items-start">
                    <!--kanal-listan -->
                    <b-col>
                        <b-card title="Kanaler">
                            <b-list-group flush class="scroll">
                                <b-list-group-item v-for="(channels, provider_group) in this.fieldData.channels" :key="provider_group">
                                    <template>
                                        <div class="header small">
                                            <b-form-checkbox
                                                class="selectAllProviderGroup"
                                                name="Alla"
                                                :value="provider_group"
                                                v-model="selectedProviderGroups"
                                                @change="selectProviderGroup(provider_group)"
                                            >
                                                {{ provider_group }}
                                            </b-form-checkbox>
                                        </div>
                                    </template>

                                    <b-form-group flush>
                                        <b-form-checkbox-group v-for="channel in channels" :key="channel.provider_key" id="checkbox-group-1" v-model="selected">
                                            <b-form-checkbox :key="channel.provider_key" :name="channel.provider_key.toString()" :disabled="true" :value="channel.provider_key">{{
                                                channel.provider_name
                                            }}</b-form-checkbox>
                                        </b-form-checkbox-group>
                                    </b-form-group>
                                </b-list-group-item>
                            </b-list-group>
                        </b-card>
                    </b-col>

                    <!--site-listan -->
                    <b-col>
                        <b-card title="Siter">
                            <b-list-group flush class="scroll">
                                <b-form-group flush>
                                    <b-form-checkbox-group v-for="site in this.fieldData.sites" :key="site.provider_key" id="checkbox-group-2" v-model="selected">
                                        <b-form-checkbox :key="site.provider_key" :disabled="true" :name="site.provider_key.toString()" :value="site.provider_key">{{
                                            site.provider_name
                                        }}</b-form-checkbox>
                                    </b-form-checkbox-group>
                                </b-form-group>
                            </b-list-group>
                        </b-card>
                    </b-col>
                </b-row>
            </b-container>

            <template #modal-footer="{  ok, cancel }">
                <b-button class="custom-border" size="sm" variant="light" @click="cancel()">
                    Avbryt
                </b-button>
                <b-button size="sm" variant="primary" @click="saveChannels(), ok()">
                    Spara
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
import ConfirmDialog from "@/components/Shared/FormElement/Dialogs/ConfirmDialog"
import props from "@/mixins/inputProps"
import providerPickerProps from "@/mixins/providerPickerProps"
import { objectArraysEqual } from "@/api/utils"

export default {
    name: "ProviderPickerPrograms",
    mixins: [props, providerPickerProps],
    components: {
        ConfirmDialog,
    },
    data() {
        return {
            confirmDialogId: "provider-picker-modal-confirm",
            checked: [],
            modalShow: false,
            selectedProviderGroups: [],
        }
    },
    mounted() {
        this.checked = this.$store.getters[this.getter][this.fieldData.identifier]
    },
    computed: {
        selected: {
            get() {
                if (this.checked.length === 0 && !this.modalShow) {
                    return this.$store.getters[this.getter][this.fieldData.identifier]
                }
                return this.checked
            },
            set(value) {
                this.checked = value
            },
        },
        getOpenModalButtonText() {
            let selectedChannelCount = this.$store.getters[this.getter][this.fieldData.identifier].length
            return selectedChannelCount == 0 ? "Välj kanaler" : `Kanaler (${selectedChannelCount} valda)`
        },
    },
    watch: {
        checked: function() {
            this.checkGroupSelections()
        },
    },
    methods: {
        saveChannels() {
            let prevValue = this.$store.getters[this.getter][this.fieldData.identifier]
            prevValue = prevValue == this.checked
            if (!prevValue) {
                this.$store.commit(this.providersSetter, this.checked)
            }
        },
        userClosePicker(bModalEvent) {
            bModalEvent.preventDefault()
            if (objectArraysEqual.length > 0) {
                //wait a tick for $bvModal
                this.$nextTick(() => {
                    if (objectArraysEqual(this.checked, this.$store.getters[this.getter][this.fieldData.identifier])) {
                        this.$bvModal.hide(this.fieldData.identifier)
                        this.modalShow = false
                    } else {
                        //show dialog if any changes
                        this.$bvModal.show(this.confirmDialogId)
                    }
                })
            }
        },
        confirmDialog(e) {
            if (e.trigger === "ok") {
                this.$bvModal.hide(this.fieldData.identifier)
                this.checked = this.$store.getters[this.getter][this.fieldData.identifier]
            }
        },
        clearChannels() {
            this.checked = []
        },
        selectAllChannels() {
            //get all channels and sites provider keys
            const allChannels = [
                ...Object.entries(this.fieldData.channels).map(([house]) => {
                    return [
                        ...this.fieldData.channels[house].map(channel => {
                            return channel.provider_key
                        }),
                    ]
                }),
            ].flat()
            const allSites = this.fieldData.sites.map(site => {
                return site.provider_key
            })
            const allProviderKeys = allChannels.concat(allSites)
            this.checked = allProviderKeys
        },
        checkGroupSelections() {
            //check if if all channels in group are selected
            let allProviders = this.getAllChannelProviderGroups()
            let removeIndices = []
            allProviders.forEach(groupId => {
                this.fieldData.channels[groupId].forEach(channel => {
                    if (!this.checked.includes(channel.provider_key)) {
                        removeIndices.push(channel.provider_group)
                    }
                })
            })
            allProviders = allProviders.filter(group => {
                return !removeIndices.includes(group)
            })
            this.selectedProviderGroups = allProviders
        },
        checkSelection() {
            return true
        },
        selectProviderGroup(providerGroup) {
            //get all channels in group
            let groupSelected = false
            if (this.getAllChannelProviderGroups().includes(providerGroup)) {
                const groupsProviderKeys = this.fieldData.channels[providerGroup].map(k => {
                    return k.provider_key
                })
                if (this.selectedProviderGroups.includes(providerGroup)) {
                    //select group
                    this.checked = [...new Set(this.checked.concat(groupsProviderKeys))]
                    groupSelected = true
                } else {
                    //deselect group
                    const filtered = this.checked.filter(k => {
                        return !groupsProviderKeys.includes(k)
                    })
                    this.checked = filtered
                }
                //select sites related to channel provider group
                if (groupSelected) {
                    this.checkSite([groupsProviderKeys[0]], true)
                } else {
                    this.checkSite([groupsProviderKeys[0]], false)
                }
            }
        },
        checkSite(channelProviderKeys, checkSite) {
            let selectedChannelSites = Object.values(this.fieldData.channels)
                .flat(2)
                .reduce((siteNames, item) => {
                    if (channelProviderKeys.includes(item.provider_key)) siteNames.push(item.provider_site_name)
                    return siteNames
                }, [])
            let siteProviderKey = this.fieldData.sites.filter(s => selectedChannelSites.includes(s.provider_site_name)).map(s => s.provider_key)
            if (checkSite) this.checked = this.checked.concat(siteProviderKey)
            else this.checked = this.checked.filter(c => !siteProviderKey.includes(c))
        },
        getProviderGroupsSite() {
            return Object.values(this.fieldData.channels)
                .flat(2)
                .reduce((all, item) => {
                    all[item.provider_group] = item.provider_site_name
                    return all
                }, {})
        },
        getAllChannelProviderGroups() {
            let allChannels = [
                ...Object.entries(this.fieldData.channels).map(([house]) => {
                    return [
                        ...this.fieldData.channels[house].map(channel => {
                            return channel.provider_group
                        }),
                    ]
                }),
            ].flat()
            allChannels = [...new Set(allChannels)]
            return allChannels
        },
        onPickerToggle(modalEvent) {
            if (modalEvent.type === "show") {
                this.checked = this.$store.getters[this.getter][this.fieldData.identifier]
                this.modalShow = true
            }
        },
        getSelectedChannelCount() {
            return this.$store.getters[this.getter][this.fieldData.identifier].length
        },
    },
}
</script>

<style scoped>
.card-body {
    padding: 0.7rem 1rem 1rem 1rem;
}
.card-title {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}
.header {
    font-weight: 700;
}

.header.small {
    display: flex;
}

.list-group-item {
    padding: 0px 8px;
}
.list-group-item:not(:first-child) {
    margin-top: 5px;
}
#checkbox-group-1 {
    padding: 0px 20px;
    display: flex;
    margin: -1px 0;
}

#checkbox-group-2 {
    padding: 0px 8px;
    display: flex;
    margin: -1px 0;
}

.selectAllProviderGroup div {
    margin-top: 4px !important;
}

.selectAllProviderGroup {
    margin-top: -2px !important;
    display: flex;
}
>>> .selectAllProviderGroup label {
    font-weight: 700;
    font-size: 101%;
    color: #212529;
}

.list-group-item > .list-group > .list-group-item {
    padding: 0px 16px;
}

.scroll {
    max-height: 400px;
    overflow: auto;
}

.selectedItemsClass {
    background-color: #e5f3ff;
    border-color: #337ab7;
    color: #6c757d;
}
</style>
