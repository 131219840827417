<template>
    <div>
        <div :class="['wrapsmallbtns', hideSmallDateButtons ? 'visibility-hidden' : '']">
            <b-button @click="clickedYesterday" v-b-tooltip.hover title="1d: Sätt konsumtionsperiod från och till, till senaste dagen med data" variant="primary" class="datefrombtn">1d</b-button>
            <b-button @click="clicked1v" v-b-tooltip.hover title="1v: Sätt konsumtionsperiod från och till, till en vecka från senaste dagen med data" variant="primary" class="datefrombtn"
                >1v</b-button
            >
            <b-button @click="clickedF1v" v-b-tooltip.hover title="f1v: Sätt konsumtionsperiod från och till, till den senaste kompletta veckan med data" variant="primary" class="datefrombtn"
                >f1v</b-button
            >
        </div>
        <date-pick
            v-model="value"
            :months="datePickerConfig.months"
            :weekdays="datePickerConfig.weekdays"
            :class="fieldData.hasError ? 'errorinput' : ''"
            :is-date-disabled="isDateDisabled"
            :inputAttributes="{ readonly: true }"
        ></date-pick>
        <label class="text-danger" v-if="fieldData.errorMsg.length > 0">{{ fieldData.errorMsg }}</label>
    </div>
</template>

<script>
import DatePick from "vue-date-pick"
import computedVal from "@/mixins/computedVal"
import props from "@/mixins/inputProps"
import propsDate from "@/mixins/inputPropsDate"
import { isDateNotBetweenTwoDates, dateToyymmddFormat } from "../../../../api/utils.js"
import dayjs from "dayjs"
import globalConfig from "../../../../mixins/globalConfig"
export default {
    name: "DateFromChannels",
    components: {
        DatePick,
    },
    mixins: [computedVal, props, propsDate, globalConfig],
    computed: {
        isDateValidFormat() {
            return dayjs(this.value, "YYYY-MM-DD").format("YYYY-MM-DD") === this.value
        },
        hideSmallDateButtons() {
            let hideSmallButtons = this.$store.getters.getChannelsQuery.grouptime === "week" || this.$store.getters.getChannelsQuery.grouptime === "sevendays"
            return hideSmallButtons
        },
    },
    data() {
        return {
            datePickerConfig: {
                weekdays: ["Må", "Ti", "On", "To", "Fr", "Lö", "Sö"],
                months: ["Januari", "Februari", "Mars", "April", "Maj", "Juni", "Juli", "Augusti", "September", "Oktober", "November", "December"],
            },
        }
    },
    async mounted() {
        await this.loadGlobalConfigFromFile()
        require("dayjs/locale/sv")
        dayjs.locale("sv")
        if (this.value) this.checkIfError(this.value)
    },
    methods: {
        clickedYesterday() {
            this.$store.commit(this.mutationSetStartdate, this.latestDate)
            this.$store.commit(this.mutationSetEnddate, this.latestDate)
        },
        clicked1v() {
            let sevenMmsDaysEarlier = dayjs(this.latestDate)
                .subtract(6, "day")
                .format("YYYY-MM-DD")
            this.$store.commit(this.mutationSetStartdate, sevenMmsDaysEarlier)
            this.$store.commit(this.mutationSetEnddate, this.latestDate)
        },
        clickedF1v() {
            let weekday = require("dayjs/plugin/weekday")
            dayjs.extend(weekday)
            let isProgramToDateSunday = dayjs(this.latestDate).weekday() == 6
            let monday = dayjs(this.latestDate)
                .weekday(isProgramToDateSunday ? 0 : -7)
                .format("YYYY-MM-DD")
            let sunday = dayjs(this.latestDate)
                .weekday(isProgramToDateSunday ? 6 : -1)
                .format("YYYY-MM-DD")
            this.$store.commit(this.mutationSetStartdate, monday)
            this.$store.commit(this.mutationSetEnddate, sunday)
        },
        isDateDisabled(datepickerDate) {
            let publishFromDate = new Date(this.$store.getters.getPublishedFromDate)
            let publishToDate = new Date(this.latestDate)

            let disableDate = false
            if (this.$store.getters.getChannelsQuery.grouptime === "day") {
                const firstDataDate = new Date("2023-01-01") //https://dev.azure.com/mmsab/BigBang/_boards/board/t/MMS%20Total-Analys/Stories/?workitem=4349
                let disableDate = isDateNotBetweenTwoDates(datepickerDate, firstDataDate, publishToDate)
                return disableDate
            }

            if (this.$store.getters.getChannelsQuery.grouptime === "sevendays") {
                var isBetween = require("dayjs/plugin/isBetween")
                dayjs.extend(isBetween)
                let sevenDaysBeforeLastPubDate = dayjs(this.latestDate).subtract(6, "d")
                let dateIsLessThanSevenDaysFromLastPubDate = dayjs(dateToyymmddFormat(datepickerDate)).isBetween(sevenDaysBeforeLastPubDate, this.latestDate, "d")
                if (
                    dateIsLessThanSevenDaysFromLastPubDate ||
                    dayjs(dateToyymmddFormat(datepickerDate)).isSame(this.latestDate, "d") ||
                    isDateNotBetweenTwoDates(datepickerDate, publishFromDate, publishToDate)
                )
                    disableDate = true
            } else if (this.$store.getters.getChannelsQuery.grouptime === "week") {
                let datepickerDateDayjsObj = dayjs(dateToyymmddFormat(datepickerDate))
                //if datepickerDate is not monday or datepickerdate is not between publishfromdate and publishtodate disable date
                if (datepickerDateDayjsObj.get("d") !== 1 || isDateNotBetweenTwoDates(datepickerDate, publishFromDate, publishToDate)) disableDate = true
            }
            return disableDate
        },
        checkIfError(chosendate) {
            let hasError = false
            this.fieldData.errorMsg = ""
            if ((this.isDateDisabled(new Date(chosendate)) && this.fieldData.required) || !this.isDateValidFormat) {
                hasError = true
                this.fieldData.errorMsg = "Det saknas tittardata för valt datum eller så är datumet i fel format"
            }
            this.$emit("onInputChanged", hasError, this.fieldData.identifier)
        },
    },
    watch: {
        value(chosendate) {
            this.checkIfError(chosendate)
        },
    },
}
</script>
<style scoped>
input {
    height: 30px !important;
    box-sizing: border-box;
    border: 1px solid #ced4da;
    padding: 2px 5px !important;
}
</style>
