<template>
    <b-form-select v-model="value" :options="fieldData.config.options" variant="primary"></b-form-select>
</template>

<script>
import computedVal from "@/mixins/computedVal"
import props from "@/mixins/inputProps"
export default {
    name: "Dropdown",
    mixins: [computedVal, props],
}
</script>

<style scoped>
select {
    height: 30px !important;
    box-sizing: border-box;
    border: 1px solid #ced4da;
    padding: 2px 5px !important;
}
</style>
