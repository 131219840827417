<template>
    <b-col sm="6" md="3" :lg="calculateLgSize" :class="calculateClass">
        <label :class="['label-text', fieldData.hasError ? 'error' : '']">
            {{ fieldData.label }}
        </label>
        <TargetGroupPrograms v-if="fieldData.type === 'target_group_programs'" v-on="$listeners" v-bind="$props" />
        <TargetGroupChannels v-else-if="fieldData.type === 'target_group_channels'" v-on="$listeners" v-bind="$props" />
        <GenrePicker v-else-if="fieldData.type === 'genre-picker'" v-on="$listeners" v-bind="$props" />
        <InputMulti v-else-if="fieldData.type === 'input_multi'" v-on="$listeners" v-bind="$props" />
        <DateFromPrograms v-else-if="fieldData.type === 'start_date_programs'" v-on="$listeners" v-bind="$props" />
        <DateFromChannels v-else-if="fieldData.type === 'start_date_channels'" v-on="$listeners" v-bind="$props" />
        <ToggleButton v-else-if="fieldData.type === 'toggle-button'" v-on="$listeners" v-bind="$props" />
        <DateToPrograms v-else-if="fieldData.type === 'end_date_programs'" v-on="$listeners" v-bind="$props" />
        <DateToChannels v-else-if="fieldData.type === 'end_date_channels'" v-on="$listeners" v-bind="$props" />
        <PublishDate v-else-if="fieldData.identifier === 'pub_date'" v-on="$listeners" v-bind="$props" />
        <TimePicker v-else-if="fieldData.type === 'time'" v-on="$listeners" v-bind="$props" />
        <Dropdown v-else-if="fieldData.type === 'dropdown'" v-on="$listeners" v-bind="$props" />
        <DropdownGroupTime v-else-if="fieldData.type === 'dropdown-grouptime-select'" v-on="$listeners" v-bind="$props" />
        <ProviderPickerPrograms v-else-if="fieldData.type === 'provider-picker-programs'" v-on="$listeners" v-bind="$props" />
        <ProviderPickerChannels v-else-if="fieldData.type === 'provider-picker-channels'" v-on="$listeners" v-bind="$props" />
        <Checkbox v-else-if="fieldData.type === 'checkbox'" v-on="$listeners" v-bind="$props" />
        <CheckboxDropdownSpottypes v-else-if="fieldData.type === 'dropdown-checkboxes-spottypes'" v-on="$listeners" v-bind="$props" />
        <InputField v-else-if="fieldData.type === 'text'" v-on="$listeners" v-bind="$props" />
        <div v-else>felaktigt formelement!: {{ fieldData.type }}</div>
    </b-col>
</template>

<script>
import TimePicker from "./TimePicker/TimePicker"
import DateFromPrograms from "./DatePicker/DateFromPrograms.vue"
import DateFromChannels from "./DatePicker/DateFromChannels.vue"
import ProviderPickerPrograms from "../../Layout/Programs/ProviderPickerPrograms.vue"
import ProviderPickerChannels from "../../Layout/Channels/ProviderPickerChannels.vue"
import DateToPrograms from "./DatePicker/DateToPrograms.vue"
import DateToChannels from "./DatePicker/DateToChannels.vue"
import PublishDate from "./DatePicker/PublishDate.vue"
import CheckboxDropdownSpottypes from "./CheckboxDropdown/CheckboxDropdownSpottypes"
import Dropdown from "./Dropdown/Dropdown"
import DropdownGroupTime from "./Dropdown/DropdownGroupTime"
import ToggleButton from "./ToggleButton/ToggleButton"
import InputField from "./InputField/InputField"
import TargetGroupPrograms from "./TargetGroup/TargetGroupPrograms"
import TargetGroupChannels from "./TargetGroup/TargetGroupChannels"
import InputMulti from "./InputMulti/InputMulti"
import GenrePicker from "./GenrePicker/GenrePicker"
import Checkbox from "./Checkbox/Checkbox"
import props from "@/mixins/inputProps"
import propsDate from "@/mixins/inputPropsDate"
import propsProviderPicker from "@/mixins/providerPickerProps"
import globalConfig from "../../../mixins/globalConfig"

export default {
    name: "FormElement",
    mixins: [props, propsDate, propsProviderPicker, globalConfig],
    components: {
        DateFromPrograms,
        DateFromChannels,
        DateToPrograms,
        DateToChannels,
        PublishDate,
        GenrePicker,
        CheckboxDropdownSpottypes,
        Dropdown,
        DropdownGroupTime,
        InputField,
        TargetGroupPrograms,
        TargetGroupChannels,
        TimePicker,
        InputMulti,
        Checkbox,
        ProviderPickerPrograms,
        ProviderPickerChannels,
        ToggleButton,
    },
    async mounted() {
        await this.loadGlobalConfigFromFile()
    },
    computed: {
        calculateLgSize() {
            let lgSize = 2
            if (this.fieldData.identifier === "pub_date" && this.$store.getters.getProgramsQuery.searchForPubDate) lgSize = 4
            return lgSize
        },
        calculateClass() {
            let formclass = ""
            if (this.fieldData.identifier == "start_date" || this.fieldData.identifier == "end_date") formclass = "formcontrolminwidth"
            if (this.fieldData.identifier === "pub_date" && !this.$store.getters.getProgramsQuery.searchForPubDate) formclass = "pubdatebutton"
            if (this.fieldData.identifier == "end_date" && !this.config_ShowConsumtionPeriodProgram && this.parentName == "programs") formclass = "d-none"

            return formclass
        },
    },
}
</script>

<style scoped>
.error {
    color: red;
    transition: color 0.3s;
}

label {
    position: relative;
}
.formcontrolminwidth {
    min-width: 14.1875rem;
}
.pubdatebutton {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}
</style>
