const mixin = {
    computed: {
        value: {
            get() {
                return this.$store.getters[this.getter]?.[this.fieldData.identifier] ? this.$store.getters[this.getter][this.fieldData.identifier] : this.fieldData.default
            },
            set(val) {
                const updatedQuery = {
                    ...this.$store.getters[this.getter],
                    [this.fieldData.identifier]: val,
                }
                return this.$store.commit(this.setter, updatedQuery)
            },
        },
    },
}

export default mixin
