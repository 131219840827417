<template>
    <div>
        <ConfirmDialog @cancel="confirmDialog" @ok="confirmDialog" :dialogId="confirmDialogId" />
        <b-button size="sm" variant="outline-secondary" v-b-modal.modal-codes :class="['w-100 btn-overflow', getFilmCodesSelectedCount() > 0 ? 'selectedItemsClass' : '']" @keydown.enter.prevent>
            {{ getButtonText }}
        </b-button>
        <b-modal id="modal-codes" title="Filmkoder" size="xl" @ok="saveFilmCodesToLocalStorage" @show="showModal" @hide="onHidden" :no-close-on-backdrop="true">
            <div class="row align-items-start">
                <div class="col-sm-8 pabBorder">
                    <label>Byrå</label>
                    <div class="input-group mb-3">
                        <v-select class="vueSelect" :options="buyingAgencies" v-model="selectedBuyingAgency"> </v-select>
                    </div>

                    <div class="input-group mb-3">
                        <label>Annonsör</label>
                        <div class="input-group">
                            <v-select class="vueSelect" :options="advertisers" v-model="selectedAdvertiser"> </v-select>
                        </div>
                    </div>
                    <div class="input-group">
                        <label>Produkt</label>
                        <div class="input-group mb-3">
                            <v-select class="vueSelect" :options="products" v-model="selectedProduct"> </v-select>
                        </div>
                        <div class="col-sm-12 px-0 mb-3">
                            <b-button variant="primary" size="sm" @click="filmCodeSearch()">
                                Sök filmkoder
                            </b-button>
                        </div>
                        <div v-if="filmCodeList.length > 0" class="col-sm-2 px-0">
                            <b-button variant="success" size="sm" @click="saveCodesFromTable()">
                                Lägg till alla
                            </b-button>
                        </div>
                        <div v-else-if="statusfilmcodes == 'notchosen_selectlists'" class="col-sm-6 mt-2 text-center">
                            Vänligen välj byrå, annonsör eller produkt
                        </div>
                        <div v-else-if="statusfilmcodes == 'empty_search'" class="col-sm-6 mt-2 text-center">
                            inget resultat
                        </div>
                        <div class="col-sm-10 px-0 d-flex align-items-center">
                            <span :style="cssVars" :class="{ filmcodeslengthcount: filmCodeList.length > 0 }"></span>
                            <span class="ml-1" v-if="filmCodeList.length > 0">Rader</span>
                        </div>
                    </div>
                    <div class="filmCodeTableWrapper" v-show="filmCodeList.length > 0">
                        <table class="table table-hover filmCodeTable table-sm">
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Filmkod</th>
                                    <th scope="col">Byrå</th>
                                    <th scope="col">Annonsör</th>
                                    <th scope="col">Produkt</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(film, index) in filmCodeList" :key="index">
                                    <th>
                                        <b-button class="text-nowrap" size="sm" variant="success" @click="saveCodeFromTable(film.copyCode)">
                                            Lägg till
                                        </b-button>
                                    </th>
                                    <td>{{ film.copyCode }}</td>
                                    <td>{{ film.buyingAgency }}</td>
                                    <td>{{ film.advertiser }}</td>
                                    <td>{{ film.productName }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="col-sm-12">
                        <label>Filmkod</label>
                        <b-form-textarea v-model="value" :placeholder="'Skriv in filmkod(er)'" :rows="1" :max-rows="8"> </b-form-textarea>
                    </div>
                    <div class="col-sm-12 mb-3">
                        <b-button class="mt-2 mr-2" size="sm" variant="success" @click="saveCode">
                            Lägg till
                        </b-button>
                        <b-button v-if="filmCodes.length > 1" variant="danger" size="sm" class="mt-2" @click="removeCodes()">
                            Ta bort alla
                        </b-button>
                    </div>
                    <div class="selectedfilmcodesclass">
                        <div class="col-sm-12" v-for="(item, index) in filmCodes" :key="'code_' + index">
                            <div class="form-row mt-2">
                                <div class="col-sm-9">
                                    {{ item }}
                                </div>
                                <div class="col-sm-3 text-right">
                                    <b-button variant="danger" size="sm" @click="removeCode(index)">
                                        Ta bort
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ cancel, ok }">
                <b-button size="sm" variant="outline-secondary" @click="cancel()">
                    Avbryt
                </b-button>
                <b-button size="sm" variant="primary" @click="ok()">
                    Spara
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import ConfirmDialog from "@/components/Shared/FormElement/Dialogs/ConfirmDialog"
import "vue-select/dist/vue-select.css"
import props from "@/mixins/inputProps"
import vSelect from "vue-select"

import { getAdvertisers, getBuyingAgencies, getProducts, getFilmCodes } from "../../../../api/api.js"
import { arraysEqual, dateToyymmddFormat } from "../../../../api/utils.js"

export default {
    name: "InputMulti",
    mixins: [props],
    components: {
        vSelect,
        ConfirmDialog,
    },
    data() {
        return {
            statusfilmcodes: "no_search",
            value: "",
            buttonText: "Filmkoder",
            advertisers: [],
            buyingAgencies: [],
            products: [],
            selectedBuyingAgency: "",
            selectedAdvertiser: "",
            selectedProduct: "",
            filmCodeList: [],
            confirmDialogId: "filmcode-modal-confirm",
        }
    },

    computed: {
        cssVars() {
            return {
                "--filmCodes-length": this.filmCodeList.length,
            }
        },
        filmCodes() {
            this.setButtonText()
            return this.$store.getters[this.getter]?.[this.fieldData.identifier] ? this.$store.getters[this.getter][this.fieldData.identifier] : []
        },
        getButtonText() {
            let NumberOf = this.getFilmCodesSelectedCount()
            if (NumberOf == 0) return "Filmkoder"
            if (NumberOf != 1) {
                return "Filmkoder (" + NumberOf + " valda)"
            }
            return "Filmkod (" + NumberOf + " vald)"
        },
    },
    methods: {
        onHidden(e) {
            let madeChanges = false
            if (e.trigger == "ok" || e.trigger == "event") {
                return
            }
            let selectedFilmcodes = this.$store.getters[this.getter][this.fieldData.identifier]

            let storedFilmCodes = JSON.parse(localStorage.getItem("savedFilmCodes"))
            storedFilmCodes = !storedFilmCodes || !storedFilmCodes[this.getter] ? [] : storedFilmCodes[this.getter]
            let filmcodesEqual = arraysEqual(selectedFilmcodes ? selectedFilmcodes : [], storedFilmCodes ? storedFilmCodes : [])
            if (!filmcodesEqual) madeChanges = true
            if (madeChanges) {
                this.$bvModal.show(this.confirmDialogId)
                e.preventDefault()
            }
        },
        confirmDialog(e) {
            if (e.trigger == "ok") this.$bvModal.hide("modal-codes")
        },
        getFilmCodesSelectedCount() {
            this.$store.getters[this.getter][this.fieldData.identifier]

            let storedFilmCodes = localStorage.getItem("savedFilmCodes") ? JSON.parse(localStorage.getItem("savedFilmCodes")) : []
            if (storedFilmCodes) {
                if (typeof storedFilmCodes[this.getter] === "undefined") storedFilmCodes = null
            }

            return storedFilmCodes != null ? storedFilmCodes[this.getter].length : 0
        },
        async filmCodeSearch() {
            this.filmCodeList = []

            if (!this.selectedAdvertiser && !this.selectedBuyingAgency && !this.selectedProduct) {
                this.statusfilmcodes = "notchosen_selectlists"
                return
            }
            let advertiserId = this.getId(this.selectedAdvertiser)
            let buyingAgencyId = this.getId(this.selectedBuyingAgency)
            let productId = this.getId(this.selectedProduct)
            let startDate = this.$store.getters.getCampaignsQuery.start_date
            let endDate = this.$store.getters.getCampaignsQuery.end_date

            if (!startDate) startDate = null
            if (!endDate) startDate = null

            await getFilmCodes({
                Advertiser: advertiserId,
                ProductName: productId,
                BuyingAgency: buyingAgencyId,
                StartDate: startDate,
                EndDate: endDate,
            })
                .then(filmcodes => {
                    this.statusfilmcodes = filmcodes.length == 0 ? "empty_search" : "not_empty_search"
                    this.filmCodeList = filmcodes
                })
                .catch(error => {
                    this.toastMessage("Problem att ladda filmkoder. Tjänsten kommer eventuellt ha problem.")
                    console.error(error)
                })
        },
        getId(val) {
            if (val == null || val == "") return ""

            let id = val.id.split("|")[0]

            return id
        },

        async loadAdvertisers() {
            let startDate = this.$store.getters.getCampaignsQuery.start_date
            let endDate = this.$store.getters.getCampaignsQuery.end_date

            if (!startDate) startDate = dateToyymmddFormat(this.setPreviousMonthFromToday())

            if (!endDate) endDate = dateToyymmddFormat(new Date())

            await getAdvertisers({
                start_date: startDate,
                end_date: endDate,
            })
                .then(advertisers => {
                    let ad = []
                    for (let i = 0; i < advertisers.length; i++) {
                        ad.push({
                            id: advertisers[i].id + "|" + advertisers[i].label,
                            label: advertisers[i].label,
                        })
                    }
                    this.advertisers = ad
                })
                .catch(error => {
                    this.toastMessage("Problem att ladda annonsörer. Tjänsten kommer eventuellt ha problem.")
                    console.error(error)
                })
        },
        async loadBuyingAgencies() {
            let startDate = this.$store.getters.getCampaignsQuery.start_date
            let endDate = this.$store.getters.getCampaignsQuery.end_date

            if (!startDate) startDate = dateToyymmddFormat(this.setPreviousMonthFromToday())

            if (!endDate) endDate = dateToyymmddFormat(new Date())

            await getBuyingAgencies({
                start_date: startDate,
                end_date: endDate,
            })
                .then(buyingAgencies => {
                    let ba = []
                    for (let i = 0; i < buyingAgencies.length; i++) {
                        ba.push({
                            id: buyingAgencies[i].id + "|" + buyingAgencies[i].label,
                            label: buyingAgencies[i].label,
                        })
                    }
                    this.buyingAgencies = ba
                })
                .catch(error => {
                    this.toastMessage("Problem att ladda byråer. Tjänsten kommer eventuellt ha problem.")
                    console.error(error)
                })
        },
        async loadProducts() {
            let startDate = this.$store.getters.getCampaignsQuery.start_date
            let endDate = this.$store.getters.getCampaignsQuery.end_date

            if (!startDate) startDate = dateToyymmddFormat(this.setPreviousMonthFromToday())

            if (!endDate) endDate = dateToyymmddFormat(new Date())

            await getProducts({
                start_date: startDate,
                end_date: endDate,
            })
                .then(products => {
                    let pr = []
                    for (let i = 0; i < products.length; i++) {
                        pr.push({
                            id: products[i].id + "|" + products[i].label,
                            label: products[i].label,
                        })
                    }

                    this.products = pr
                })
                .catch(error => {
                    this.toastMessage("Problem att ladda produkter. Tjänsten kommer eventuellt ha problem.")
                    console.error(error)
                })
        },

        toastMessage(message) {
            this.$bvToast.toast(message, {
                title: "Varning",
                variant: "warning",
                solid: true,
                autoHideDelay: 3000,
            })
        },
        setPreviousMonthFromToday() {
            var d = new Date()
            var newMonth = d.getMonth() - 1
            if (newMonth < 0) {
                newMonth += 12
                d.setYear(d.getFullYear() - 1) // use getFullYear instead of getYear !
            }
            d.setMonth(newMonth)
            return d
        },
        saveCode() {
            this.saveCodeFromTable(this.value)
        },
        saveCodeFromTable(filmcode) {
            if (filmcode.length === 0) return
            const filmCodeArray = filmcode.split(/[\s,]+/)
            const updatedCodes = [...this.filmCodes]
            const numCodes = filmCodeArray.length

            for (let ip = 0; ip < numCodes; ip++) {
                if (updatedCodes.includes(filmCodeArray[ip])) {
                    this.$bvToast.toast("Filmkoden " + `${filmCodeArray[ip]}` + " finns redan.", {
                        title: "Varning",
                        variant: "warning",
                        solid: true,
                        autoHideDelay: 3000,
                    })
                    return
                }
            }

            filmCodeArray.forEach(element => updatedCodes.push(element.toUpperCase()))

            const updatedQuery = {
                ...this.$store.getters[this.getter],
                [this.fieldData.identifier]: updatedCodes,
            }
            this.value = ""
            this.$store.commit(this.setter, updatedQuery)
        },
        saveCodesFromTable() {
            let allFilmcodes = this.filmCodeList.map(element => {
                return element.copyCode
            })
            const updatedQuery = {
                ...this.$store.getters[this.getter],
                [this.fieldData.identifier]: allFilmcodes,
            }
            this.$store.commit(this.setter, updatedQuery)
        },

        removeCode(index) {
            const updatedCodes = [...this.filmCodes]
            updatedCodes.splice(index, 1)

            const updatedQuery = {
                ...this.$store.getters[this.getter],
                [this.fieldData.identifier]: updatedCodes,
            }

            this.$store.commit(this.setter, updatedQuery)
        },
        removeCodes() {
            const updatedQuery = {
                ...this.$store.getters[this.getter],
                [this.fieldData.identifier]: [],
            }

            this.$store.commit(this.setter, updatedQuery)
        },
        setButtonText() {
            let filmCodes = this.$store.getters[this.getter]?.[this.fieldData.identifier]
            this.buttonText = filmCodes.length == 1 ? `Filmkoder (${filmCodes.length} vald)` : `Filmkoder (${filmCodes.length} valda)`
        },
        showModal() {
            this.loadAdvertisers()
            this.loadBuyingAgencies()
            this.loadProducts()
            this.setFilmCodesFromLocalStorage()
        },
        setFilmCodesFromLocalStorage() {
            this.filmCodeList = []
            let storedFilmCodes = JSON.parse(localStorage.getItem("savedFilmCodes"))
            if (storedFilmCodes) {
                if (typeof storedFilmCodes[this.getter] === "undefined") storedFilmCodes = null
            }
            const updatedQuery = {
                ...this.$store.getters[this.getter],
                [this.fieldData.identifier]: storedFilmCodes ? storedFilmCodes[this.getter] : [],
            }

            this.$store.commit(this.setter, updatedQuery)
        },
        saveFilmCodesToLocalStorage() {
            let selectedFilmCodes = this.$store.getters[this.getter][this.fieldData.identifier]
            const updatedQuery = {
                ...this.$store.getters[this.getter],
                [this.fieldData.identifier]: selectedFilmCodes,
            }
            this.$store.commit(this.setter, updatedQuery)
            let storedFilmCodes = localStorage.getItem("savedFilmCodes") ? JSON.parse(localStorage.getItem("savedFilmCodes")) : {}
            storedFilmCodes[this.getter] = selectedFilmCodes
            localStorage.setItem("savedFilmCodes", JSON.stringify(storedFilmCodes))
        },
    },
}
</script>

<style scoped>
label {
    width: 100%;
}

.vueSelect {
    width: 60%;
}

.pabBorder {
    border-right: 1px solid black;
}
.filmCodeTableWrapper {
    height: 500px;
    overflow-x: hidden;
}
.filmCodeTable {
    margin-top: 15px;
}
.selectedfilmcodesclass {
    max-height: 703px;
    overflow: auto;
}
tbody {
    display: block;
    height: 432px;
    overflow-x: hidden;
}
thead,
tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}
thead {
    width: calc(100% - 1em);
}
@property --num {
    syntax: "<integer>";
    initial-value: 0;
    inherits: false;
}
.filmcodeslengthcount {
    transition: --num 2s;
    counter-set: num var(--num);
    display: table-cell;
    vertical-align: middle;
}
.filmcodeslengthcount::after {
    content: counter(num);
}
.filmcodeslengthcount {
    --num: var(--filmCodes-length);
}
</style>
