<template>
    <div>
        <b-tooltip v-if="fieldData.tooltip_text" :target="fieldData.identifier" triggers="hover">
            {{ fieldData.tooltip_text }}
        </b-tooltip>
        <b-form-checkbox :id="fieldData.identifier" size="sm" :type="fieldData.type" v-model="value"></b-form-checkbox>
    </div>
</template>

<script>
import computedVal from "@/mixins/computedVal"
import props from "@/mixins/inputProps"
export default {
    name: "Checkbox",
    mixins: [computedVal, props],
}
</script>

<style scoped>
input {
    height: 30px !important;
    box-sizing: border-box;
    border: 1px solid #ced4da;
    padding: 2px 5px !important;
}
</style>
