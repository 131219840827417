<template>
    <div>
        <date-pick
            v-model="value"
            :months="datePickerConfig.months"
            :weekdays="datePickerConfig.weekdays"
            :class="['dateto', fieldData.hasError ? 'errorinput' : '', fieldData.editable === false ? 'disableddateto' : '']"
            :is-date-disabled="isDateDisabled"
            :editable="fieldData.editable"
            :inputAttributes="{ readonly: true }"
        ></date-pick>
        <label class="text-danger" v-if="fieldData.errorMsg.length > 0">{{ fieldData.errorMsg }}</label>
    </div>
</template>

<script>
import DatePick from "vue-date-pick"
import computedVal from "@/mixins/computedVal"
import props from "@/mixins/inputProps"
import propsDate from "@/mixins/inputPropsDate"
import { isDateNotBetweenTwoDates, dateToyymmddFormat } from "../../../../api/utils.js"
import dayjs from "dayjs"
export default {
    name: "DateToChannels",
    components: {
        DatePick,
    },
    mixins: [computedVal, props, propsDate],
    computed: {
        isDateValidFormat() {
            return dayjs(this.value, "YYYY-MM-DD").format("YYYY-MM-DD") === this.value
        },
    },
    data() {
        return {
            datePickerConfig: {
                weekdays: ["Må", "Ti", "On", "To", "Fr", "Lö", "Sö"],
                months: ["Januari", "Februari", "Mars", "April", "Maj", "Juni", "Juli", "Augusti", "September", "Oktober", "November", "December"],
            },
        }
    },
    mounted() {
        if (this.value) this.checkIfError(this.value)
    },
    methods: {
        isDateDisabled(datepickerDate) {
            let disableDate = false
            let startDate = new Date(this.$store.getters.getPublishedFromDate)
            let endDate = new Date(this.latestDate)
            let datepickerDateDayjsObj = dayjs(dateToyymmddFormat(datepickerDate))
            let isWeek = this.$store.getters.getChannelsQuery.grouptime === "week"
            //if datepicker date is not sunday and week is chosen or datepickerdate is not between publishfromdate and publishenddate disable date
            if ((datepickerDateDayjsObj.get("d") !== 0 && isWeek) || isDateNotBetweenTwoDates(datepickerDate, startDate, endDate)) disableDate = true
            return disableDate
        },
        checkIfError(chosendate) {
            let hasError = false
            this.fieldData.errorMsg = ""
            if ((this.isDateDisabled(new Date(chosendate)) && this.fieldData.required) || !this.isDateValidFormat) {
                hasError = true
                this.fieldData.errorMsg = "Det saknas tittardata för valt datum eller så är datumet i fel format"
            }
            this.$emit("onInputChanged", hasError, this.fieldData.identifier)
        },
    },
    watch: {
        value(chosendate) {
            this.checkIfError(chosendate)
        },
    },
}
</script>

<style scoped>
input {
    height: 30px !important;
    box-sizing: border-box;
    border: 1px solid #ced4da;
    padding: 2px 5px !important;
}
.disableddateto >>> input {
    background-color: var(--vs-disabled-bg);
    cursor: var(--vs-disabled-cursor);
}
</style>
