const formSelects = config => [
    {
        label: "Grupp",
        class: "col-sm-4 form-selects",
        value: "all",
        identifier: "group",
        disabled: false,
        options: [
            {
                value: "all",
                selectedAgeGroupText: "A",
                text: "Alla",
            },
            {
                value: "female",
                selectedAgeGroupText: "K",
                text: "Kvinnor",
            },
            {
                value: "male",
                selectedAgeGroupText: "M",
                text: "Män",
            },
        ],
    },
    {
        label: "Mått",
        class: "col-sm-4 form-selects",
        value: "reach",
        identifier: "measure",
        disabled: false,
        options: [...config.measures],
    },
    {
        label: "Konsolidering",
        class: "col-sm-4 form-selects",
        value: "-1",
        identifier: "consolidation",
        disabled: false,
        options: [
            {
                value: "-1",
                text: "KOperiod",
            },
        ],
    },
    {
        label: "Ålder från",
        class: "col-sm-6 form-selects",
        value: "3",
        identifier: "age_from",
        disabled: false,
        options: [
            {
                value: "3",
                text: "3",
                disabled: false,
            },
            {
                value: "15",
                text: "15",
                disabled: false,
            },
            {
                value: "20",
                text: "20",
                disabled: false,
            },
            {
                value: "25",
                text: "25",
                disabled: false,
            },
            {
                value: "30",
                text: "30",
                disabled: false,
            },
            {
                value: "45",
                text: "45",
                disabled: false,
            },
            {
                value: "50",
                text: "50",
                disabled: false,
            },
            {
                value: "60",
                text: "60",
                disabled: false,
            },
            {
                value: "65",
                text: "65",
                disabled: false,
            },
        ],
    },
    {
        label: "Ålder till",
        class: "col-sm-6 form-selects",
        value: "99",
        identifier: "age_to",
        disabled: false,
        options: [
            {
                value: "14",
                text: "14",
                disabled: false,
            },
            {
                value: "19",
                text: "19",
                disabled: false,
            },
            {
                value: "24",
                text: "24",
                disabled: false,
            },
            {
                value: "29",
                text: "29",
                disabled: false,
            },
            {
                value: "44",
                text: "44",
                disabled: false,
            },
            {
                value: "49",
                text: "49",
                disabled: false,
            },
            {
                value: "59",
                text: "59",
                disabled: false,
            },
            {
                value: "64",
                text: "64",
                disabled: false,
            },
            {
                value: "99",
                text: "99",
                disabled: false,
            },
        ],
    },
    {
        label: "Reachvillkor TV",
        class: "col-sm-6 form-selects",
        value: "1",
        identifier: "reach_tv",
        disabled: false,
        options: [
            {
                value: "1",
                text: "1 min",
                selectedReachTvText: "1min",
                disabled: false,
            },
            {
                value: "3",
                text: "3 min",
                selectedReachTvText: "3min",
                disabled: false,
            },
            {
                value: "5",
                text: "5 min",
                selectedReachTvText: "5min",
                disabled: false,
            },
            {
                value: "10",
                text: "10 min",
                selectedReachTvText: "10min",
                disabled: false,
            },
            {
                value: "15",
                text: "15 min",
                selectedReachTvText: "15min",
                disabled: false,
            },
            {
                value: "30",
                text: "30 min",
                selectedReachTvText: "30min",
                disabled: false,
            },
        ],
    },
    {
        label: "Reachvillkor Online",
        class: "col-sm-6 form-selects",
        value: "15",
        identifier: "reach_online",
        disabled: false,
        options: [
            {
                value: "1",
                text: "1 sek",
                selectedReachOnlineText: "1s",
                disabled: false,
            },
            {
                value: "5",
                text: "5 sek",
                selectedReachOnlineText: "5s",
                disabled: false,
            },
            {
                value: "10",
                text: "10 sek",
                selectedReachOnlineText: "10s",
                disabled: false,
            },
            {
                value: "15",
                text: "15 sek el 50% av längd",
                selectedReachOnlineText: "15s",
                disabled: false,
            },
            {
                value: "20",
                text: "20 sek",
                selectedReachOnlineText: "20s",
                disabled: false,
            },
            {
                value: "30",
                text: "30 sek",
                selectedReachOnlineText: "30s",
                disabled: false,
            },
            {
                value: "60",
                text: "60 sek",
                selectedReachOnlineText: "60s",
                disabled: false,
            },
            {
                value: "120",
                text: "120 sek",
                selectedReachOnlineText: "120s",
                disabled: false,
            },
            {
                value: "180",
                text: "180 sek",
                selectedReachOnlineText: "180s",
                disabled: false,
            },
        ],
    },
]
export default formSelects
