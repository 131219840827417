<template>
    <div>
        <div class="wcDiv" v-show="fieldData.identifier == 'title'">
            <label
                v-b-tooltip.hover
                class="wlabel"
                title="Anges i något av följande exempelformat:
Sportnytt, Sport*, *nytt, *ortny*, eller via Titelkod"
            >
                Wildcardstöd

                <b-form-checkbox size="sm" type="checkbox" class="wCheckbox" id="with_wildcards" v-model="wildcard"></b-form-checkbox
            ></label>
        </div>

        <b-tooltip v-if="fieldData.tooltip_text" :target="fieldData.identifier" triggers="hover">
            {{ fieldData.tooltip_text }}
        </b-tooltip>
        <b-form-input :id="fieldData.identifier" size="sm" :type="fieldData.type" v-model="value"></b-form-input>
    </div>
</template>

<script>
import computedVal from "@/mixins/computedVal"
import props from "@/mixins/inputProps"

export default {
    name: "InputField",
    mixins: [computedVal, props],

    computed: {
        wildcard: {
            get: function() {
                return this.$store.getters[this.getter]["with_wildcards"]
            },
            set: function(checkedState) {
                const updatedQuery = {
                    ...this.$store.getters[this.getter],
                    with_wildcards: checkedState,
                }
                return this.$store.commit(this.setter, updatedQuery)
            },
        },
    },
}
</script>

<style scoped>
input {
    height: 30px !important;
    box-sizing: border-box;
    border: 1px solid #ced4da;
    padding: 2px 5px !important;
}

.wcDiv {
    float: right;
    margin-top: 8px;
    height: 13px;
}

.wlabel {
    float: right;
    margin: 0px;
    font-weight: normal;
    font-size: xx-small;
}
.wInfo {
    transform: scale(1.4);
    margin-left: 2px;
}
.wCheckbox {
    float: right;
    margin-top: -5px;
    margin-left: 5px;
    transform: scale(0.9);
}
</style>
