<template>
    <div>
        <b-tooltip v-if="fieldData.tooltip_text" :target="fieldData.identifier" triggers="hover">
            {{ fieldData.tooltip_text }}
        </b-tooltip>

        <b-form-checkbox
            button
            :id="fieldData.identifier"
            size="sm"
            button-variant="outline-secondary"
            :class="['customButton']"
            buttonClass="w-100 btn-overflow"
            :type="fieldData.type"
            v-model="pressed"
            >{{ pressed ? fieldData.active_text : fieldData.inactive_text }}
        </b-form-checkbox>
    </div>
</template>

<script>
import computedVal from "@/mixins/computedVal"
import props from "@/mixins/inputProps"
export default {
    name: "ToggleButton",
    mixins: [computedVal, props],
    computed: {
        pressed: {
            get() {
                return this.$store.getters[this.getter][this.fieldData.identifier]
            },

            set(value) {
                const new_state = { ...this.$store.getters[this.getter], [this.fieldData.identifier]: value }
                this.$store.commit(this.setter, new_state)
            },
        },
    },
}
</script>

<style scoped>
.customButton {
    width: 100% !important;
}

/deep/ .customButton > label {
    width: 100% !important;
}

/deep/ .customButton > .btn-outline-secondary:not(:disabled):not(.disabled).active {
    background-color: #e5f3ff;
    border-color: #337ab7;
    color: #6c757d;
}
</style>
